@tailwind utilities;

.free-name {
  @apply max-md:bg-purple-200 max-md:text-purple-900;
}

.pro-name {
  @apply max-md:bg-purple-600 max-md:text-white;
}

.starter-name {
  @apply max-md:bg-purple-500 max-md:text-white;
}

.pro_plus-name {
  @apply max-md:bg-purple-700 max-md:text-white;
}

.premium-name {
  @apply max-md:bg-purple-800 max-md:text-white;
}

.free {
  @apply text-purple-900 max-md:bg-purple-200;
}

.starter {
  @apply text-white border-gray-200 max-md:bg-purple-500;
}

.pro {
  @apply text-white border-gray-200 max-md:bg-purple-600;
}

.pro_plus {
  @apply text-white border-gray-200 max-md:bg-purple-700;
}

.premium {
  @apply text-white border-gray-200 max-md:bg-purple-800;
}
