@tailwind base;

:is(code, pre)[class*="language-"] {
  @apply !text-sm;
}

:root {
  --nav-link-color: theme(colors.black);
  --nav-link-hover-bg: theme(colors.black);
  --nav-link-hover-color: theme(colors.white);
  --nav-link-focus-outline: theme(colors.pink.600);

  --nav-btn-bg: theme(colors.pink.600);
  --nav-btn-color: theme(colors.white);
  --nav-btn-hover-bg: theme(colors.pink.700);
  --nav-btn-focus-outline: theme(colors.pink.600);
  --nav-btn-active-bg: theme(colors.pink.700);
  --nav-btn-active-color: theme(colors.white);

  --nav-bars-color: theme(colors.black);
}

.index-page {
  --nav-link-color: theme(colors.white);
  --nav-link-hover-bg: theme(colors.white);
  --nav-link-hover-color: theme(colors.pink.600);
  --nav-link-focus-outline: theme(colors.white);

  --nav-btn-bg: theme(colors.white / 90%);
  --nav-btn-color: theme(colors.pink.600);
  --nav-btn-hover-bg: theme(colors.white);
  --nav-btn-focus-outline: theme(colors.white);
  --nav-btn-active-bg: theme(colors.white);
  --nav-btn-active-color: theme(colors.pink.600);

  --nav-bars-color: theme(colors.white);
}

.pricing-page {
  --nav-link-color: theme(colors.white);
  --nav-link-hover-bg: theme(colors.white);
  --nav-link-hover-color: theme(colors.purple.900);
  --nav-link-focus-outline: theme(colors.white);

  --nav-btn-bg: theme(colors.white / 90%);
  --nav-btn-color: theme(colors.purple.900);
  --nav-btn-hover-bg: theme(colors.white);
  --nav-btn-focus-outline: theme(colors.white);
  --nav-btn-active-bg: theme(colors.white);
  --nav-btn-active-color: theme(colors.purple.900);

  --nav-bars-color: theme(colors.white);
}

.zapier-landing {
  --nav-link-color: theme(colors.black);
  --nav-link-hover-bg: theme(colors.black);
  --nav-link-hover-color: #cde4e1;
  --nav-link-focus-outline: theme(colors.black);

  --nav-btn-bg: #ff4f01;
  --nav-btn-color: theme(colors.white);
  --nav-btn-hover-bg: rgba(255, 79, 1, 0.8);
  --nav-btn-focus-outline: #ff4f01;
  --nav-btn-active-bg: #ff4f01;
  --nav-btn-active-color: theme(colors.white);

  --nav-bars-color: theme(colors.black);
}
